:root {
  --font-title: 'Montserrat';
  --margin-section: 50px;
}

.shadow-sm {
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.6), 0 2px 10px 0 rgba(0, 0, 0, 0.5) !important;
}

.iconSize {
  width: 50px;
  height: 50px;
  padding: 10px;
}